<template>
  <div class="list-block" :class="{ 'viewer-page__list-block_hidden': isHiddenMaterialList }">
    <div class="list-block__info">
      <div class="list-block__title">{{ $t('header.content') }}</div>
      <div class="list-block__close-button">
        <SmallCloseButton @click="$emit('update:isHiddenMaterialList', !isHiddenMaterialList)" />
      </div>
    </div>
    <div class="list-block__list scroll">
      <transition name="component-fade" mode="out-in">
        <div key="loading" v-if="loading">
          <div class="container f-center mt-5">
            <div>
              <Loader />
            </div>
          </div>
        </div>
        <ul key="content" v-else>
          <template v-if="courseContent.length">
            <component
              v-for="(item, index) in courseContent"
              :is="computeComponent(item)"
              :item="computeItem(item)"
              :index="index"
              :class="{ active: isActive(item) }"
              :key="item.activity._id"
              :selectMaterial="selectMaterial"
              :lastActiveActivityId="lastActiveActivityId"
              @click.native="selectMaterial(item)"
            />
          </template>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import MaterialViewerTile from '@/components/Cards/MaterialViewerTile.vue';
import Loader from '@/components/Loader/Loader.vue';
import { materialType } from '@/utils/constants';
import CollapsibleGroup from '@/components/CollapsibleGroup.vue';

export default {
  name: 'MaterialListViewer',
  components: {
    CollapsibleGroup,
    Loader,
    SmallCloseButton,
    MaterialViewerTile,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    isHiddenMaterialList: {
      type: Boolean,
      required: true,
    },
    selectMaterial: {
      type: Function,
      required: true,
    },
    lastActiveActivityId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('courseContentStudent', ['selectedActivity', 'content']),
    courseContent() {
      return this.content?.children || [];
    },
  },
  methods: {
    computeComponent(item) {
      if (materialType.includes(item.activity.type)) {
        return MaterialViewerTile;
      }

      if (item.activity.type === 'group') {
        return CollapsibleGroup;
      }
      return null;
    },
    computeItem(item) {
      if (materialType.includes(item.activity.type)) {
        return item.activity;
      }

      if (item.activity.type === 'group') {
        return item;
      }
    },
    isActive(item) {
      if (this.$route.query.activityId) {
        return this.$route.query.activityId === item.activity._id;
      }
      return this.selectedActivity?._id === item.activity._id;
    },
  },
};
</script>
