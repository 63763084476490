<!-- eslint-disable max-len -->
<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.164062 1.38158C0.164062 0.618553 0.694252 0 1.34827 0H4.99634C5.31405 0 5.61843 0.148936 5.84108 0.413338L7.11862 1.93041C7.34127 2.19481 7.64565 2.34375 7.96336 2.34375H13.9798C14.6339 2.34375 15.1641 2.9623 15.1641 3.72533V13.6184C15.1641 14.3814 14.6339 15 13.9799 15H1.34827C0.694252 15 0.164062 14.3814 0.164062 13.6184V1.38158Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'FolderIcon',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    color() {
      return this.active ? '#FAA433' : '#999999';
    },
  },
};
</script>
