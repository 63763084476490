import StudentService from '@/services/student.service';
import { ActivityStatuses } from './statusConstants';

class ActivityTimeMetrics {
  _activity = null;

  _timeSpent = 0;

  _idleTime = 0;

  _lostTime = 0;

  _trackTimeoutInterval = null;

  _isTrackerDisabled = false;

  ignoreInactive = false;

  constructor(activity) {
    this._activity = activity;

    this._init();
  }

  _init() {
    if (
      this._activity.statistic.status === ActivityStatuses.done
      || (
        this._activity.statistic.status === ActivityStatuses.rejected
        && this._activity.meta?.attempts > 0
        && this._activity.meta?.attempts <= this._activity.statistic?.meta?.used_attempts
      )) {
      this._isTrackerDisabled = true;
      return;
    }
    if (localStorage.getItem(`lostTime-${this._activity._id}`)) {
      this._lostTime = parseInt(localStorage.getItem(`lostTime-${this._activity._id}`), 10);
    }

    this._setListeners();

    this._trackTimeoutInterval = setInterval(async () => {
      // Iterate time only if user is active
      const isActive = this._idleTime <= 300 || this.ignoreInactive;
      const isOnPage = !document.hidden;
      if (isActive && isOnPage) {
        this._iterateTime();
      }

      // Send time by 5 second packs
      if (this._timeSpent >= 5) {
        const timeToSend = this._timeSpent;
        this._timeSpent = 0;
        await this._sendToServer(timeToSend)
          .catch(() => {
            this._lostTime += timeToSend;
            localStorage.setItem(`lostTime-${this._activity._id}`, this._lostTime);
          });
      }
    }, 1000);
  }

  async _sendToServer(time) {
    let accTime = time;
    if (this._lostTime > 0) {
      accTime += this._lostTime;
      this._lostTime = 0;
      localStorage.removeItem(`lostTime-${this._activity._id}`);
    }
    return StudentService.submitSpentTime(this._activity._id, accTime);
  }

  _setListeners() {
    window.addEventListener('beforeunload', async () => {
      await StudentService.submitSpentTimeOnLeave(this._activity._id, this._timeSpent);
    });

    window.addEventListener('click', () => { this._idleTime = 0; });
    window.addEventListener('touchmove', () => { this._idleTime = 0; });
    window.addEventListener('mousemove', () => { this._idleTime = 0; });
    window.addEventListener('keydown', () => { this._idleTime = 0; });
  }

  _removeListeners() {
    window.removeEventListener('click', () => { this._idleTime = 0; });
    window.removeEventListener('touchmove', () => { this._idleTime = 0; });
    window.removeEventListener('mousemove', () => { this._idleTime = 0; });
    window.removeEventListener('keydown', () => { this._idleTime = 0; });
  }

  _removeTimeTracker() {
    clearInterval(this._trackTimeoutInterval);
  }

  _iterateTime() {
    this._timeSpent += 1;
    this._idleTime += 1;
  }

  async destroy() {
    if (this._isTrackerDisabled) {
      return;
    }
    // send last time spent on component leaving
    if ((this._idleTime <= 300 || this.ignoreInactive) && !document.hidden) {
      const timeToSend = this._timeSpent;
      this._timeSpent = 0;
      await this._sendToServer(timeToSend)
        .catch(() => {
          this._lostTime += timeToSend;
          localStorage.setItem(`lostTime-${this._activity._id}`, this._lostTime);
        });
    }
    this._removeListeners();
    this._removeTimeTracker();
  }

  updateActivity(activity) {
    this._activity = activity;
    this._activity._id = activity._id;
  }
}

export default ActivityTimeMetrics;
