<template>
  <div
    class="answer-student-tile"
    :class="answerState"
    @click.prevent.stop="$emit('change', answer.id)"
  >
    <div class="answer-student-tile__text">{{ answer.text }}</div>
    <div class="answer-student-tile__checkbox">
      <RadioButton
        :isTypeCheckBox="isMultipleQuestion"
        :isSending="isSending"
        :isQuestionCompleted="isQuestionCompleted"
        @change="$emit('change', answer.id)"
        :checked="isChecked"
      />
    </div>
  </div>
</template>

<script>
import RadioButton from '@/components/Buttons/RadioButton.vue';

export default {
  name: 'AnswerTileStudent',
  props: {
    answer: { type: Object, required: true },
    isMultipleQuestion: { type: Boolean, required: true },
    answerState: { type: String, default: '' },
    isSending: { type: Boolean, default: false },
    isQuestionCompleted: { type: Boolean, default: false },
  },
  components: {
    RadioButton,
  },
  computed: {
    isChecked() {
      const checked = ['selected', 'invalid', 'valid'];
      return checked.includes(this.answerState);
    },
  },
};
</script>
