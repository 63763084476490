<template>
  <!-- eslint-disable max-len -->
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.0003 0.667969C24.5603 0.667969 0.666992 24.5613 0.666992 54.0013C0.666992 83.4413 24.5603 107.335 54.0003 107.335C83.4403 107.335 107.334 83.4413 107.334 54.0013C107.334 24.5613 83.4403 0.667969 54.0003 0.667969ZM54.0003 96.668C30.4803 96.668 11.3337 77.5213 11.3337 54.0013C11.3337 30.4813 30.4803 11.3346 54.0003 11.3346C77.5203 11.3346 96.667 30.4813 96.667 54.0013C96.667 77.5213 77.5203 96.668 54.0003 96.668Z"
      fill="#EB5757"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.666992 54.0013C0.666992 24.5613 24.5603 0.667969 54.0003 0.667969C83.4403 0.667969 107.334 24.5613 107.334 54.0013C107.334 83.4413 83.4403 107.335 54.0003 107.335C24.5603 107.335 0.666992 83.4413 0.666992 54.0013ZM76.271 24.2109L83.791 31.7309L61.5202 54.0018L83.791 76.2727L76.271 83.7927L54.0002 61.5218L31.7293 83.7927L24.2093 76.2727L46.4802 54.0018L24.2093 31.7309L31.7293 24.2109L54.0002 46.4818L76.271 24.2109Z"
      fill="#EB5757"
    />
  </svg>
</template>

<script>
export default {
  name: 'FailedQuizIcon',
};
</script>
