<template>
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable max-len -->
    <path
      d="M64.0002 10.666C34.5602 10.666 10.6668 34.5593 10.6668 63.9993C10.6668 93.4393 34.5602 117.333 64.0002 117.333C93.4402 117.333 117.334 93.4393 117.334 63.9993C117.334 34.5593 93.4402 10.666 64.0002 10.666ZM64.0002 106.666C40.4802 106.666 21.3335 87.5193 21.3335 63.9993C21.3335 40.4793 40.4802 21.3327 64.0002 21.3327C87.5202 21.3327 106.667 40.4793 106.667 63.9993C106.667 87.5193 87.5202 106.666 64.0002 106.666Z"
      fill="#1CE890"
    />
    <!-- eslint-disable max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.668 65.9993C10.668 36.5593 34.5613 12.666 64.0013 12.666C93.4413 12.666 117.335 36.5593 117.335 65.9993C117.335 95.4393 93.4413 119.333 64.0013 119.333C34.5613 119.333 10.668 95.4393 10.668 65.9993ZM53.3345 77.5731L88.4811 42.4265L96.0011 49.9998L53.3345 92.6665L32.0011 71.3331L39.5211 63.8131L53.3345 77.5731Z"
      fill="#1CE890"
    />
  </svg>
</template>
<script>
export default {
  name: 'CompleteQuizIcon',
};
</script>
