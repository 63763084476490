<template>
  <div class="empty-quiz">
    <p class="empty-quiz__title">{{ $t('supportText.noQuestionsInQuiz') }}</p>
    <div class="empty-quiz__icon">
      <img src="@/assets/images/svg/board.svg" alt="Доска" />
    </div>
    <p class="empty-quiz__label">{{ $t('supportText.waitUntilAdministratorAddQuestions') }}</p>
  </div>
</template>

<script>
export default {
  name: 'EmptyQuiz',
};
</script>
