<template>
  <div
    ref="viewerPlayer"
    class="viewer-player container"
    :class="{ 'viewer-player--visible': !loading }"
  >
    <transition name="component-fade" mode="out-in">
      <PdfPlayer v-if="activity.type === 'material'" />
      <QuizPlayer v-else-if="activity.type === 'quiz'" :activity="activity" />
      <VideoPlayer
        v-else-if="activity.type === 'video'"
        :activity="activity"
        :key="activity.meta.url"
      />
      <TaskPlayer v-else-if="activity.type === 'task'" :activity="activity" />
      <div v-else class="player-container" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PdfPlayer from '@/components/Viewer/pdfPlayer.vue';
import QuizPlayer from '@/components/Viewer/quizPlayer.vue';
import VideoPlayer from '@/components/Viewer/videoPlayer.vue';
import TaskPlayer from '@/components/Viewer/taskPlayer.vue';

export default {
  name: 'ViewerPlayer',
  components: {
    VideoPlayer,
    PdfPlayer,
    QuizPlayer,
    TaskPlayer,
  },
  props: {
    activity: {
      type: Object,
      default: null,
    },
    isHiddenMaterialList: {
      type: Boolean,
      required: true,
    },
  },
  provide() {
    const isHiddenMaterialList = {};
    Object.defineProperty(isHiddenMaterialList, 'value', {
      enumerable: true,
      get: () => this.isHiddenMaterialList,
    });
    return { isHiddenMaterialList };
  },

  computed: {
    ...mapGetters({
      loading: 'viewer/getIsLoading',
    }),
  },
  methods: {
    ...mapActions('materialStudent', ['setCurrentPage']),
  },
};
</script>
