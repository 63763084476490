<template>
  <div ref="player" class="player-container" :class="{ 'player-container--visible': !loading }">
    <LeraPdfPlayer
      ref="playerLms"
      v-if="src"
      :src="src"
      class="player"
      :page="currentPage"
      :rerenderPage="rerenderPageTrigger"
      @page-rendered="setLoading(false)"
      @page-loaded="setCurrentPage"
    />
    <div class="player-control">
      <div class="player-control__left">
        <PreviousSmallArrow @click="throttle(setPreviousPage)" />
        <NextSmallArrow @click="throttle(setNextPage)" />
        <input
          v-model.number="inputValue"
          type="number"
          class="player-control__input"
          max="pagesCounter"
        />
        <div v-show="pagesCounter" class="player-control__info-total-page">
          {{ $t('supportText.of') }} {{ pagesCounter }}
        </div>
      </div>
      <div class="player-control__right">
        <FullScreenButton @click="toggleFullScreen" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FullScreenButton from '@/components/Buttons/FullScreenButton.vue';
import PreviousSmallArrow from '@/components/Buttons/PreviousSmallArrow.vue';
import NextSmallArrow from '@/components/Buttons/NextSmallArrow.vue';
import { LeraPdfPlayer } from '@centergame/lera-vue-library';
import fscreen from '@/utils/fullScreen';
import ActivityTimeMetrics from '@/utils/activityTimeMetrics';

export default {
  name: 'PdfPlayer',
  components: {
    FullScreenButton,
    PreviousSmallArrow,
    NextSmallArrow,
  },
  data: () => ({
    throttleDelayMs: 250,
    time: Date.now(),
    src: null,
    inFullscreenMode: false,
    rerenderPageTrigger: 0,
    timeMetrics: null,
  }),
  created() {
    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener('fullscreenchange', this.handleFullscreenChange, false);
    }
    window.addEventListener('keydown', this.handleArrowKeys);

    this.timeMetrics = new ActivityTimeMetrics(this.selectedActivity);
  },
  computed: {
    ...mapGetters({
      currentPage: 'materialStudent/getCurrentPage',
      pagesCounter: 'materialStudent/getPagesCounter',
      pdfisLoading: 'viewer/getIsLoading',
      loading: 'viewer/getIsLoading',
    }),
    ...mapGetters({
      selectedActivity: 'courseContentStudent/selectedActivity',
    }),
    materialMeta() {
      return this.selectedActivity?.meta;
    },
    inputValue: {
      get() {
        return this.currentPage;
      },
      set(currentPage) {
        if (currentPage > 0 && currentPage <= this.pagesCounter) {
          this.setCurrentPage(currentPage);
        }
      },
    },
  },
  methods: {
    ...mapActions('materialStudent', ['loadPdf', 'setCurrentPage', 'setPagesCount']),
    ...mapActions('viewer', ['setLoading']),
    toggleFullScreen() {
      if (this.inFullscreenMode) {
        fscreen.exitFullscreen();
      } else {
        const { player } = this.$refs;
        fscreen.requestFullscreen(player);
      }
    },
    setPreviousPage() {
      if (this.currentPage > 1) {
        const previousPage = this.currentPage - 1;
        this.setCurrentPage(previousPage);
        this.scrollPageToTop();
      }
    },
    setNextPage() {
      if (this.currentPage < this.pagesCounter) {
        const nextPage = this.currentPage + 1;
        this.setCurrentPage(nextPage);
        this.scrollPageToTop();
      }
    },
    throttle(callback) {
      if (this.time + this.throttleDelayMs - Date.now() < 0) {
        callback();
        this.time = Date.now();
      }
    },
    scrollPageToTop() {
      this.$nextTick(() => {
        if (this.$refs.playerLms.$el) {
          this.$refs.playerLms.$el.scrollTop = 0;
        }
      });
    },
    handleFullscreenChange() {
      if (fscreen.fullscreenElement !== null) {
        this.inFullscreenMode = true;
        this.$nextTick(() => (this.rerenderPageTrigger += 1));
      } else {
        this.inFullscreenMode = false;
      }
    },
    handleArrowKeys(e) {
      if (e.key === 'ArrowRight' && !this.pdfisLoading) {
        e.preventDefault();
        this.throttle(this.setNextPage);
      }
      if (e.key === 'ArrowLeft' && !this.pdfisLoading) {
        e.preventDefault();
        this.throttle(this.setPreviousPage);
      }
    },
  },
  watch: {
    materialMeta() {
      this.scrollPageToTop();
    },
    'materialMeta.url': {
      async handler(link) {
        if (link) {
          this.src = LeraPdfPlayer.createLoadingTask(`https://${link}`);
          await this.src.promise.then(this.setPagesCount).catch(console.log);
          this.setLoading(false);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.setCurrentPage(1);
    window.removeEventListener('keydown', this.handleArrowKeys);
    fscreen.removeEventListener('fullscreenchange', this.handleFullscreenChange);

    if (this.timeMetrics && this.timeMetrics.destroy) {
      this.timeMetrics.destroy();
    }
  },
};
</script>
